<template>
    <div class="row">
        <div class="col-md-6">
            <seccion-datos :titulo="configuracioncentralita" @cambios="guardar()">
                    <div class="form-group">
                        <label for="nombre">{{ $t('general.extension') }}</label>
                        <input type="text" v-model="extension" class="form-control extension dato" id="extension" placeholder="Extensión asignada" >
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group" title="Con esta opción aparecerá el teléfono en el menú en el cual podrá llamar a otras extensiones y ver las llamadas recibidas de que expedientes son.">
                                <label for="nombre">Bria&nbsp;&nbsp;</label>
                                <input type="checkbox" v-model="bria_usuario" :true-value="1" :false-value="0" class="bria_usuario dato" id="bria_usuario" value="1" >
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group" title="Con esta opción cuando se está en una llamada, no se recibirá ninguna otra llamada." id="celda_bria_nosimultanea_llamada">
                                <label for="nombre">{{ $t('general.nollamadassimultaneas') }}&nbsp;&nbsp;</label>
                                <input type="checkbox" v-model="bria_nosimultanea_llamada" :true-value="1" :false-value="0" class="bria_nosimultanea_llamada dato" id="bria_nosimultanea_llamada" value="1" >
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="telefono_asociado">{{ $t('general.numerodetelefonoasociado') }}<br /><small>{{ $t('general.numerodetelefonoasociado2') }}</small></label>
                        <input type="text" v-model="telefono_asociado" class="form-control telefono_asociado dato" id="telefono_asociado" placeholder="Introducir número de telf">
                    </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario'],
    data() {
        return {
            extension: '',
            bria_nosimultanea_llamada: '',
            bria_usuario: '',
            telefono_asociado: '',
            id: '',
            configuracioncentralita: "Configuración Centralita",
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.extension = this.usuario.extension;
            this.bria_nosimultanea_llamada = this.usuario.bria_nosimultanea_llamada;
            this.bria_usuario = this.usuario.bria_usuario;
            this.telefono_asociado = this.usuario.telefono_asociado;
            this.id = this.usuario.id;
            this.$store.dispatch('cambiarCargando', false);
            this.configuracioncentralita = this.$t('general.configuracioncentralita'); 
        },
        async guardar() {
            try {
                this.$store.dispatch('cambiarGuardando', true);
                const api = new PwgsApi;
                const subidadatos = { extension: this.extension, bria_nosimultanea_llamada: this.bria_nosimultanea_llamada, bria_usuario: this.bria_usuario, telefono_asociado: this.telefono_asociado };
                await api.put('usuarios/' + this.id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
    },
    watch: {
        usuario() {
            this.cargardatos();
        }
    },
    mounted() {
        this.cargardatos();   
    }
}
</script>